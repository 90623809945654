<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card outlined class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>Übersicht</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field filled rounded dense prepend-inner-icon="mdi-magnify" hide-details v-model="search"
              class="shrink">
            </v-text-field>
            <v-btn fab @click="$refs.assessment.open()" x-small class="ml-4" depressed color="primary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container fluid>
            <v-row>
              <v-col>
                <v-data-table :headers="headers" :items="items" :items-per-page="5" :search="search"
                  no-data-text="Keine Datenpannen vorhanden" @click:row="open"
                  :footer-props="{ 'items-per-page-options': [10, 25, 50], 'items-per-page-text': 'Einträge pro Seite' }">
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <AssessmentDialog ref="assessment"></AssessmentDialog>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
  components: { AssessmentDialog: () => import('@/components/assessment/AssessmentDialog.vue') },
  data: () => ({
    search: "",
    headers: [
      { text: "Name", value: "name", sortable: false },
    ],
    items: [],
  }),
  methods: {
    open(item) {
      this.$router.push({ name: "detail", params: { id: item.id } });
    },
  },
}
</script>

<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-title>Datenpannen Management</v-app-bar-title>
      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-btn text :to="{ name: 'home' }" exact :link="false" active-class="">Zur Übersicht</v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.theme--dark.v-btn--active::before {
  opacity: 0;
}
</style>